// Import the functions you need from the SDKs you need
import { useFirestore } from "vuefire";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getApp, getApps, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { collection, connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: "AIzaSyCfxItvYyRPIXQg022EJaGtmzg6F8g5Dwc",
	authDomain: "customer-app-45f18.firebaseapp.com",
	projectId: "customer-app-45f18",
	storageBucket: "customer-app-45f18",
	messagingSenderId: "1009883748677",
	appId: "1:1009883748677:web:88341d09cdd47b10ff062c",
	measurementId: "G-XMHWDMNLMX",
};

let firebaseApp, firebaseDb;

// Initialize Firebase, if it hasn't been initialized yet
if (!getApps().length) {
	firebaseApp = initializeApp(firebaseConfig);
} else {
	firebaseApp = getApp(); // if already initialized, use that one
}
if (location.hostname === "localhost" && 1 == 2) {
	firebaseDb = getFirestore(firebaseApp);
} else {

	firebaseDb = useFirestore();
}
const auth = getAuth(firebaseApp);
const analytics = getAnalytics(firebaseApp);
const firebaseFunctions = getFunctions(firebaseApp);
if (location.hostname === "localhost" && 1 == 2) {
	connectFirestoreEmulator(firebaseDb, "127.0.0.1", 8080);
	connectFunctionsEmulator(firebaseFunctions, "127.0.0.1", 5001);
}

// Collections
export const usersCollection = collection(firebaseDb, "/users");
export const dealsCollection = collection(firebaseDb, "/deals");
export const settingsCollection = collection(firebaseDb, "/settings");
export const referralSourceCollection = collection(firebaseDb, "/referralSources");
export const voicemailsCollection = collection(firebaseDb, "/voicemails");
export const trainingCompaniesCollection = collection(firebaseDb, "/trainingCompanies");
export const mailCollection = collection(firebaseDb, "/mail");
export const scheduledEventsCollection = collection(firebaseDb, "/scheduledEvents");

// Export the initialized Firebase app and database
export { analytics, auth, firebaseApp, firebaseDb, firebaseFunctions, logEvent };
